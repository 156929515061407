@use '@/server/styles/layout/_layout.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.memberCount {
  color: var(--charcoalToWhite);
  margin-bottom: 16px;
}

.avatars {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  li {
    margin-bottom: 16px;
  }

  @include layout.md {
    justify-content: space-between;
  }
}

.flexStart {
  justify-content: flex-start;
}
.spaceBetween {
  justify-content: flex-start;
  @include layout.md {
    justify-content: space-between;
  }
}

.additionalAvatar {
  flex-shrink: 0;
  flex-grow: 0;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.additionalAvatarWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.additionalAvatarWrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.countOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
