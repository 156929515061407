@use '@/server/styles/layout/_layout.scss';

.homeIcon {
  background-color: var(--primary);
  border-radius: 40px;
  margin: 0;
  width: 240px;
  height: 240px;
  overflow: hidden;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  top: 10px;

  span {
    display: flex;
    height: 100%;
    width: 100%;
    color: var(--shadeFour);
    font-size: 32px; 
    font-weight: 600;
    align-items: center;
    justify-content: center;
  }

  &.small {
    border-radius: 4px;
    width: 16px;
    height: 16px; 
    margin-right: 16px; 
    top: 0;

    span {
      font-size: 12px;
    }
  }
}
