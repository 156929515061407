$font-family-acid-grotesk: 'Acid Grotesk', sans-serif;
$font-family-feature-display-web: 'Feature Display Web', serif;
$font-family-suisse-intl: 'Suisse Intl', sans-serif;
$font-family-euclid-circular-a: 'Euclid Circular A', sans-serif;
$font-family-suisse-intl-mono: 'Suisse Intl Mono';

$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

@mixin feature-display-span {
  span,
  strong {
    font-family: $font-family-feature-display-web;
    font-style: normal;
    font-weight: $font-weight-800;
  }
}

@mixin feature-display-span-2 {
  font-style: normal;
  font-family: $font-family-acid-grotesk;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2;

  em {
    font-family: $font-family-feature-display-web;
  }

  strong {
    font-weight: 800;
    font-family: $font-family-feature-display-web;
  }
}

@mixin display-3xl {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 132px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-2xl {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 86px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-xl {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 64px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-lg {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 44px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-md {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 36px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-sm {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 30px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin display-xs {
  font-family: $font-family-acid-grotesk;
  font-style: normal;
  font-size: 25px;
  font-weight: $font-weight-500;
  line-height: 1;
  @include feature-display-span;
}

@mixin heading1 {
  font-family: $font-family-suisse-intl;
  font-size: 24px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading1-5 {
  font-family: $font-family-suisse-intl;
  font-size: 20px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading2 {
  font-family: $font-family-suisse-intl;
  font-size: 18px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading3 {
  font-family: $font-family-suisse-intl;
  font-size: 16px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading4 {
  font-family: $font-family-suisse-intl;
  font-size: 16px;
  font-weight: $font-weight-500;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading5 {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: $font-weight-700;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading6 {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading7 {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: $font-weight-500;
  line-height: 120%;
  @include feature-display-span;
}

@mixin heading9 {
  font-family: $font-family-suisse-intl;
  font-size: 12px;
  font-weight: $font-weight-600;
  line-height: 120%;
  @include feature-display-span;
}

@mixin chat-text {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

@mixin chat-text-italic {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 150%;
}

@mixin chat-text-bold {
  font-family: $font-family-suisse-intl;
  font-size: 14px;
  font-weight: $font-weight-600;
  font-style: normal;
  line-height: 150%;
}

@mixin info {
  font-family: $font-family-suisse-intl;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
}

@mixin info-italic {
  font-family: $font-family-suisse-intl;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  line-height: 130%;
}

@mixin info-medium {
  font-family: $font-family-suisse-intl;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
}

@mixin caption {
  font-family: $font-family-suisse-intl;
  font-size: 12px;
  font-weight: $font-weight-600;
  line-height: 120%;
}

@mixin heading10-label {
  font-family: $font-family-suisse-intl;
  font-size: 10px;
  font-weight: 400;
  line-height: 120%;
}

@mixin heading10-bold {
  font-family: $font-family-suisse-intl;
  font-size: 10px;
  font-weight: $font-weight-700;
  line-height: 120%;
}

@mixin hero-subhead {
  font-family: $font-family-euclid-circular-a;
  font-size: 20px;
  font-weight: $font-weight-700;
  line-height: 120%;
}

@mixin hero-descriptor {
  font-family: $font-family-euclid-circular-a;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
}

@mixin hero-descriptor-semibold {
  font-family: $font-family-euclid-circular-a;
  font-size: 14px;
  font-weight: $font-weight-600;
  line-height: 130%;
}

@mixin hero-descriptor-sm {
  font-family: $font-family-euclid-circular-a;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
}

@mixin sans-serif {
  font-family: $font-family-suisse-intl;
}

@mixin monospace {
  font-family: $font-family-suisse-intl-mono;
}

