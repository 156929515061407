@use '@/server/styles/typography/typography';
@use '@/server/styles/layout/layout';

.mobileOnly {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include layout.lg {
    display: none;
  }
}

.flowerIcon {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  transform: scale(0.9);
}


.ctaText {
  @include typography.feature-display-span-2;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  margin-bottom: 24px;
  
  strong {
    font-weight: 800;
  }
}

.ctaTextMobileWrapper {
  margin-top: 24px;
}

.layoutWrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  max-height: 100%;
  background-color: var(--whiteToBlack);
  color: var(--charcoalToLightFogGray);
  height: 100%;
  .sidebar {
    display: none;
  }
  @include layout.lg {
    grid-template-columns: 1fr 439px;
    .sidebar {
      display: flex;
      border-left: medium solid var(--lightFogGrayToDarkCharcoal);
      overflow-y: auto;
    }
  }
}

.left {
  max-height: 100%;
  overflow-y: scroll;
  padding-right: -8px;

  @include layout.lg {
    max-height: calc(100% - 100px);
    padding-top: 100px;
  }
}


.innerLeft {
  width: calc(100% - 32px);
  max-width: 600px;
  padding-right: 16px;
  padding-left: 16px;
  margin: 0 auto;
}

.sidebar {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 32px;
  justify-content: center;
  .brand {
    justify-content: center;
    margin-bottom: 120px;
  }

  a {
    margin-bottom: 12px;
    width: 257px;
  }
}

.innerSidebar {
  padding-top: 60px;
  text-align: center;
  position: sticky;
  top: 0;
  height: 300px;
  h3 {
    margin-bottom: 60px;
  }
}

.ctaPadding {
  padding-top: 30px;
}


.invitedBy {
  display: flex;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  height: 60px;
  justify-content: center;
  cite {
    display: block;
    @include typography.heading9;
    font-style: normal;
    margin-left: 7px;
  }
  @include layout.lg {
    margin-top: 24px;
    margin-: 24px;
    height: 26px;
  }
}


.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  max-height: 100%;
  background-color: var(--whiteToBlack);
  color: var(--charcoalToLightFogGray);
  height: 100%;
}

.brand {
  display: flex;
  align-items: center;
  padding: 40px 60px 18px 40px;
}

.mobileBrand {
  display: flex;
  align-items: center;
  padding: 40px 20px 18px 20px;
  @include layout.lg {
    display: none;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.ctaTextMobileMargin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footerFlowerIcon {
  box-sizing: border-box;
  width: 100vw;
  max-width: 500px;
  height: auto;
  margin-top: 20px;
}

