@use '@/server/styles/layout/_layout.scss';
.root {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 4px;
  align-items: flex-start;

  dt {
    @include layout.sr-only;
  }

  dd {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 8px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 500;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 16px;
      &.timeIcon {
        transform: scale(1.4);
        position: relative;
        top: 3px;
        left: 2px;
      }
    }
  }
}

.datetime {
  margin-left: 10px;
  color:  var(--coolGray)
}
