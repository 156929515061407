.root {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0;
  background-color: var(--defaultColor);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 40px;
    width: 40px;
    color: var(--whiteToCharcoal);
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(134.03deg, rgb(127, 129, 134, 0) 14.09%, rgba(245, 245, 249, 1) 84.08%);
  }

  &.hasImage::after {
    content: none;
  }

  img {
    position: relative;
    object-fit: cover;
  }

  @media (prefers-color-scheme: dark) {
    &::after {
      background: linear-gradient(134.03deg, rgb(127, 129, 134, 0) 14.09%, rgba(60, 60, 60, 1) 84.08%);
    }
  }
}

.sm {
  height: 24px;
  width: 24px;

  svg {
    transform: scale(0.6);
  }
}

.md {
  height: 32px;
  width: 32px;

  svg {
    transform: scale(0.7);
  }
}

.lg {
  height: 42px;
  width: 42px;

  svg {
    transform: scale(0.8);
  }
}

.xl {
  height: 62px;
  width: 62px;
  svg {
    transform: scale(0.9);
  }
}

.xxl {
  height: 176px;
  width: 176px;
  svg {
    transform: scale(1.2);
  }
}

.outline {
  box-shadow: 0 0 0 2px var(--whiteToBlack);
}
