@use '@/server/styles/typography/typography';
@use '@/server/styles/layout/layout';

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.homeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightFogGrayToDarkCharcoal);
  text-align: center;
  border-radius: 16px;
  max-width: 480px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 32px 64px 32px 64px;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
}

.iconContainer {
  padding-top: 10px;
}

.homeName {
  @include typography.display-md;
  margin-top: 20px;
  padding-top: 20px;
}

.homeDescription {
  @include typography.chat-text;
  margin-top: 10px;
  padding-top: 10px;
}

.iconRow {
  display: flex;
  justify-content: center;
  gap: 12px;
  @include typography.monospace;
  font-size: 14px;
  line-height: 1.2;
  color: var(--lightCharcoalToLightCoolGray);
}

.members,
.location,
.interests {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-top: 8px;
  color: var(--coolGrayToDarkCharcoal);
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;

    padding-left: 8px;
    padding-right: 8px;
    border-radius: 20px;
    background-color: var(--whiteToBlack);
    box-shadow: 0 0 0 1px var(--fogGrayToCharcoal);
    color: var(--lightCharcoalToWhite);
    white-space: nowrap;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.location {
  svg {
    margin-right: 4px;
    top: 1px;
    left: 1px;
    transform: scale(1.05);
  }
}

.members,
.interests {
  svg {
    top: -1px;
    margin-right: 7px;
    left: 1px;
  }
}

.interest {
  margin-right: 7px;
}
