@use '@/server/styles/typography/typography';
@use '@/server/styles/layout/layout';

.button {
  box-sizing: border-box;
  background-color: var(--primary);
  color: var(--buttontextcolor);
  border: none;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  @include typography.heading4;

  &:hover {
    background-color: var(--shadeOne);
  }

  &.outline {
    box-shadow: 0 0 0 2px var(--black);
    color: var(--black);
    border-radius: 10px;
    background-color: var(--offlineBlue);
  }

  &.highlighter {
    color: var(--black);
    font-weight: 400;
    border-radius: 32px;
    background-color: var(--highlighter);
    border: none;
    &:hover {
      background-color: var(--highlighterShadeOne);
    }
  }

  &.homeThemed {
    color: var(--buttontextcolor);
    font-weight: 400;
    border-radius: 32px;
    background-color: var(--primary);
    border: none;
    &:hover {
      background-color: var(--shadeOne);
    }
  }

  &.square {
    border-radius: 6px;
  }

  &.sm {
    font-size: 12px;
    height: 32px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.md {
    height: 48px;
    font-size: 16px;
    padding-left: 27px;
    padding-right: 27px;
  }

  &.lg {
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;
    @include typography.display-sm;
    font-size: 32px;
  }

  svg {
    width: 17px;
    height: 20px;
    margin-right: 8px;
  }
}

.link {
  @include typography.heading6;
  appearance: none;
  outline: none;
  color: var(--primary);
  text-decoration: none;
  &:hover {
    color: var(--shadeOne);
  }

  &.highlighter {
    color: var(--coolGrayToLightFogGray);
    text-align: center;
    &:hover {
      color: var(--charcoalToWhite);
    }
  }

  &.homeThemed {
    color: var(--primary);
    text-align: center;
    &:hover {
      color: var(--shadeOne);
    }
  }
}
